import logo from './logo.svg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAppleWhole, faCheckSquare, faThumbsUp, faCode} from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <h2>trallala</h2>
        
          <ul>
            <li> I fixed it! <FontAwesomeIcon icon={ faAppleWhole}/></li>
            <li> task <FontAwesomeIcon icon={ faCheckSquare}/></li>
            {/*
            <li><FontAwesomeIcon icon={ faCode }/></li>
            <li><FontAwesomeIcon icon={ faCode }/></li>
            */}
          </ul>  
        

        {/*      
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        */}

      </header>
    </div>
  );
}

export default App;
